<script setup>
  import BaseIcon from './BaseIcon.vue';
  import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
</script>

<template>
  <div class="relative">
    <button @click="toggleDropdown" class="py-2 px-0 rounded-lg inline-flex items-center">
      {{ selectedOption ? selectedOption.label : 'Select an option' }}
      <BaseIcon :path="isOpen ? mdiChevronUp : mdiChevronDown" :size="18" />
    </button>

    <div v-if="isOpen" class="mt-2 py-2 bg-white border rounded-lg shadow-lg absolute left-0 min-w-max w-full">
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
        <li class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" v-for="(option, index) in options" :key="index" @click="onOptionClick(option)">
          {{ option.label ? option.label : option.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectOption: {
      type: Function,
      required: true,
    },
    preselectedOption: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOption: this.preselectedOption,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    onOptionClick(option) {
      this.selectOption(option);
      this.selectedOption = option;
      this.isOpen = false;
    },
  },
  components: { BaseIcon }
};
</script>

<style>
/* You can style the dropdown here using Tailwind CSS classes */
</style>
