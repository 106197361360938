<template>
  <div class="chartContainer">
    <apexchart height="100%" ref="chart" width="100%" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style scoped>
  .chartContainer {
    height: 100%;
    min-height: 350px;
  }
</style>

<script>

export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    chartLabel: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      default: () => ['#FF5733', '#FFC300', '#DAF7A6', '#C70039', '#900C3F']
    }
  },
  data() {
    return {
      chartOptions: {
        colors: this.colors,
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            barHeight: '50%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        legend: {
          show: false,
        },
        title: {
          text: this.chartLabel
        },
        xaxis: {
          categories: this.labels,
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
      },
    }
  },
  watch: {
    labels(newLabels) {
      this.updateChart(newLabels);
    },
    series(newSeries) {
      if (this.$refs.chart) {
        this.$refs.chart.updateSeries(newSeries);
      }
    }
  },
  methods: {

    updateChart(newLabels) {
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: newLabels
          },

        });
      }
    }
  }
}
</script>
