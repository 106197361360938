<template>
  <div class="chartContainer">
    <apexchart ref="chart" height="100%" width="100%" type="radar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>


<style scoped>
  .chartContainer {
    height: 100%;
    min-height: 350px;
  }
</style>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    chartLabel: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          type: 'radar',
        },
        title: {
          text: this.chartLabel
        },
        xaxis: {
          categories: this.labels,
        },
      },
    }
  },
  watch: {
    labels(newLabels, oldLabels) {
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: newLabels
          },
        });
      }
    },
    series(newSeries) {
      if (this.$refs.chart) {
        this.$refs.chart.updateSeries(newSeries);
      }
    }
  }
};
</script>
