

<template>
  <HorizontalBarChart :colors="['#B72136']" v-if="chartData.labels.length > 0 && chartData.series.length > 0" chartLabel="Negative Topics"
    :labels="chartData.labels" :series="chartData.series" />
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { useMainStore } from "@/stores/main";
import HorizontalBarChart from "@/components/Charts/HorizontalBarChart.vue";

const mainStore = useMainStore();

const chartData = reactive({
  labels: [],
  series: []
});

const updateChartData = () => {
  const series = [];
  const labels = [];

  mainStore.negativeTopics.forEach(item => {
    series.push(item.review_count);
    labels.push(item.topic_label);
  });

  chartData.labels = labels;

  chartData.series = [{
    name: 'Count',
    data: series
  }];
};

watch(() => mainStore.negativeTopics, () => {
  updateChartData();
});

updateChartData();
</script>
