<script setup>
import { computed } from "vue";
import { mdiChevronUp, mdiChevronDown, mdiAlertCircleOutline } from "@mdi/js";
import BaseIcon from "@/components/BaseIcon.vue";
import { colorsText, colorsOutline } from "@/colors.js";

const props = defineProps({
  trend: {
    type: String,
    required: true,
  },
  trendType: {
    type: String,
    default: null,
  },
});

const trendStyle = computed(() => {
  if (props.trendType === "up") {
    return {
      icon: mdiChevronUp,
      style: colorsText["success"],
    };
  }

  if (props.trendType === "down") {
    return {
      icon: mdiChevronDown,
      style: colorsText["danger"],
    };
  }

  if (props.trendType === "alert") {
    return {
      icon: mdiAlertCircleOutline,
      style: colorsText["warning"],
    };
  }

  return {
    style: colorsText["info"],
  };
});

</script>

<template>
  <div class="flex flex-col items-end capitalize leading-none" :class="trendStyle.style">
    <span class="text-base font-bold">
      <span v-if="trendType">
        <BaseIcon v-if="trendStyle.icon" :path="trendStyle.icon" h="h-4" w="w-4" />
      </span>{{ trend }}
    </span>

    <small class="text-gray-500 text-xs">30 day trend</small>
  </div>
</template>
