
export function getGraphColor(name) {
  // Return the color for the given graph
  switch (name) {
    case 'FACEBOOK':
      return '#1877F2';
    case 'GOOGLE':
      return '#FF4040';
    case 'HELLOPETER':
      return '#29357f';
    case 'FRESHA':
      return '#6950f3';
    case 'TRIPADVISOR':
      return '#34E0A1';
    default:
      return '#000000';
  }
}


// Utility function to map periods to start and end dates
export  function mapPeriodToDateRange(period) {
  const currentDate = new Date(); // Get current date
  let startDate = new Date();
  let endDate = new Date();

  // Set the time for startDate and endDate based on the selected period
  switch (period) {
    case 'Today':
      // Set start date to the beginning of the current day
      startDate.setHours(0, 0, 0, 0);
      break;
    case 'Last 7 Days':
      // Set start date to 7 days ago and end date to today
      startDate.setDate(currentDate.getDate() - 6); // 7 days ago
      break;
      case 'Last 14 Days':
        // Set start date to 7 days ago and end date to today
        startDate.setDate(currentDate.getDate() - 13); // 7 days ago
        break;
    case 'Last 30 Days':
      // Set start date to 30 days ago and end date to today
      startDate.setDate(currentDate.getDate() - 29); // 30 days ago
      break;
    case 'Last 90 Days':
      // Set start date to 30 days ago and end date to today
      startDate.setDate(currentDate.getDate() - 89); // 30 days ago
      break;
    // Add more cases for other periods as needed
    default:
      // For 'All Time' or any other default period, set start date to a past date (e.g., Jan 1, 1970)
      startDate = new Date(0); // Jan 1, 1970
      break;
  }

  // Set the end date to the current date
  endDate = currentDate;

  // Return an object containing start and end dates
  return { startDate, endDate };
}

export function formatTime(totalMinutes) {
  if (totalMinutes < 0) {
    return { isValid: false };
  }

  const days = Math.floor(totalMinutes / (24 * 60));
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = totalMinutes % 60;

  if (days > 0) {
    return { isValid: true, value: days, suffix: ` day${days !== 1 ? 's' : ''}` };
  } else if (hours > 0) {
    return { isValid: true, value: hours, suffix: ` hour${hours !== 1 ? 's' : ''}` };
  } else {
    return { isValid: true, value: minutes, suffix: ` min${minutes !== 1 ? 's' : ''}` };
  }
}
