<template>
  <div class="chartContainer">
    <apexchart height="100%" ref="chart" width="100%" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style scoped>
  .chartContainer {
    height: 100%;
    min-height: 350px;
  }
</style>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    chartLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: this.chartLabel,
        },
        xaxis: {
          categories: this.labels
        },

      }
    }
  },
  watch: {
    labels(newLabels) {
      this.updateChart(newLabels);
    },
    series(newSeries) {
      if (this.$refs.chart) {
        this.$refs.chart.updateSeries(newSeries);
      }
    }
  },
  methods: {
    updateChart(newLabels) {
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: newLabels
          }
        });
      }
    }
  },
};
</script>
