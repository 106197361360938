import {
  mdiAccountCircle,
  mdiMonitor,
  mdiTable,
  mdiFencing,
  mdiForum,
} from "@mdi/js";

export default [
  {
    to: "/",
    icon: mdiMonitor,
    label: "Dashboard",
  },
  {
    to: "/reviews",
    label: "Reviews",
    icon: mdiTable,
  },
  {
    to: "/competitors",
    label: "Competitors",
    icon: mdiFencing,
  },
  {
    to: "/socials",
    label: "Social Buzz",
    icon: mdiForum,
  },
  // {
  //   to: "/forms",
  //   label: "Forms",
  //   icon: mdiSquareEditOutline,
  // },
  // {
  //   to: "/ui",
  //   label: "UI",
  //   icon: mdiTelevisionGuide,
  // },
  {
    to: "/profile",
    label: "Profile",
    icon: mdiAccountCircle,
  },
  // {
  //   to: "/login",
  //   label: "Login",
  //   icon: mdiLock,
  // },
];
