<script setup>
import { ref, reactive, watch } from 'vue';
import { useMainStore } from "@/stores/main";
import LineChartMultiSeries from "@/components/Charts/LineChartMultiSeriesChartjs.vue";

const mainStore = useMainStore();
// Use reactive to make chartData reactive
const chartData = reactive(mainStore.reviewCountByPlatform);

</script>

<template>
  <LineChartMultiSeries v-if="chartData.dates.length > 0 && chartData.series.length > 0" chartLabel="Reviews By Platform"
    :chartData="mainStore.reviewCountByPlatform" />
</template>
