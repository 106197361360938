<script setup>
import { containerMaxW } from "@/config.js";
import BaseLevel from "@/components/BaseLevel.vue";
import buzzTrackerLogo from "@/assets/buzztracker-logo.png";
const year = new Date().getFullYear();
</script>

<template>
  <footer class="py-2 px-6" :class="containerMaxW">
    <BaseLevel>
      <div class="text-center md:text-left">
        <b
          >&copy;{{ year }},
          <a href="https://BuzzTracker.co.za/" target="_blank">BuzzTracker.co.za</a>.</b
        >
        <slot />
      </div>
      <div class="md:py-2">
        <a href="https://BuzzTracker.co.za">
          <img :src="buzzTrackerLogo" alt="BuzzTracker Logo" class="h-auto max-h-8" />
        </a>
      </div>
    </BaseLevel>
  </footer>
</template>
