<script setup>
import { onMounted } from "vue";
import { useMainStore } from "@/stores/main";
import {
  mdiChartTimelineVariant,
} from "@mdi/js";
import SectionMain from "@/components/SectionMain.vue";
import CardBox from "@/components/CardBox.vue";
import LayoutAuthenticated from "@/layouts/LayoutAuthenticated.vue";
import SectionTitleLineWithButton from "@/components/SectionTitleLineWithButton.vue";
import SentimentByPlatform from "@/components/Charts/SentimentByPlatform.vue";
import ReviewCountByPlatformByMonth from "@/components/Charts/ReviewCountByPlatformByMonth.vue";
import RadarChart from "@/components/Charts/RadarChart.vue";
import CardBoxLabel from "@/components/CardBoxLabel.vue";
import CardBoxStats from "@/components/CardBoxStats.vue";
import CardBoxStatsSkeleton from "@/components/CardBoxStatsSkeleton.vue";
import CardBoxSkeleton from "@/components/CardBoxSkeleton.vue";
import DropDown from "@/components/Dropdown.vue";
import NegativeTopicsBarChart from "@/components/Charts/NegativeTopicsBarChart.vue";
import ReviewVolumeBySentiment from "@/components/Charts/ReviewVolumeBySentiment.vue";
import PositiveTopicsBarChart from "@/components/Charts/PositiveTopicsBarChart.vue";

const mainStore = useMainStore();

onMounted(() => { });
</script>

<template>
  <LayoutAuthenticated>
    <SectionMain>
      <SectionTitleLineWithButton :icon="mdiChartTimelineVariant" title="Overview" main>
        <DropDown></DropDown>
      </SectionTitleLineWithButton>
      <div v-if="mainStore.overview && mainStore.loading != true" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mb-6">
        <div>
          <CardBoxStats :color="mainStore.overview.reviews.color" :icon="mainStore.overview.reviews.icon"
            :number="mainStore.overview.reviews.total" :breakdown="mainStore.overview.reviews.breakdown"
            :trend="mainStore.overview.reviews.trend"
            :trendType="mainStore.overview.reviews.trendType"
            label="Reviews" />
        </div>
        <div>
          <CardBoxStats :color="mainStore.overview.sentiment.color" :icon="mainStore.overview.sentiment.icon"
            :number="mainStore.overview.sentiment.total" :breakdown="mainStore.overview.sentiment.breakdown"
            :trend="mainStore.overview.sentiment.trend"
            :trendType="mainStore.overview.sentiment.trendType"
            label="Sentiment" />
        </div>
        <div>
          <CardBoxStats :color="mainStore.overview.ratings.color" :icon="mainStore.overview.ratings.icon"
            :number="mainStore.overview.ratings.total" :breakdown="mainStore.overview.ratings.breakdown"
            :trend="mainStore.overview.ratings.trend"
            :trendType="mainStore.overview.ratings.trendType"
            label="Ratings" />
        </div>
        <div>
          <CardBoxStats :color="mainStore.overview.avgRating.color" :icon="mainStore.overview.avgRating.icon"
            :number="mainStore.overview.avgRating.total" :breakdown="mainStore.overview.avgRating.breakdown"
            :trend="mainStore.overview.avgRating.trend"
            :trendType="mainStore.overview.avgRating.trendType"
            label="Avg Rating" />
        </div>
        <div>
          <CardBoxStats :color="mainStore.overview.avgRating.color" :icon="mainStore.overview.avgResponseTime.icon"
            :number="mainStore.overview.avgResponseTime.total" :suffix="mainStore.overview.avgResponseTime.suffix"
            :trend="mainStore.overview.avgResponseTime.trend"
            :trendType="mainStore.overview.avgResponseTime.trendType"
            :breakdown="mainStore.overview.avgResponseTime.breakdown" label="Avg Response Time" />
        </div>
      </div>
      <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mb-6" v-else>
        <div>
          <CardBoxStatsSkeleton />
        </div>
        <div>
          <CardBoxStatsSkeleton />
        </div>
        <div>
          <CardBoxStatsSkeleton />
        </div>
        <div>
          <CardBoxStatsSkeleton />
        </div>
        <div>
          <CardBoxStatsSkeleton />
        </div>
      </div>

      <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6">
        <CardBox :isChart="true" v-if="mainStore.reviewCountByPlatform && mainStore.loading != true">
          <ReviewCountByPlatformByMonth />
        </CardBox>
        <CardBoxSkeleton v-else />




        <CardBox :isChart="true" v-if="mainStore.reviewVolumeAndSentiment && mainStore.loading != true">
          <ReviewVolumeBySentiment />
        </CardBox>
        <CardBoxSkeleton v-else />
        <CardBoxSkeleton v-else />

      </div>

      <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6">
        <CardBox :isChart="true" v-if="mainStore.reviewsByEmotion && mainStore.loading != true">
          <RadarChart chartLabel="Reviews By Emotion" :labels="mainStore.reviewsByEmotion.emotionLabels"
            :series="mainStore.reviewsByEmotion.emotionSeries" />
        </CardBox>
        <CardBoxSkeleton v-else />

        <CardBox :isChart="true" v-if="mainStore.sentimentByPlatform && mainStore.loading != true">
          <SentimentByPlatform />
        </CardBox>
        <CardBoxSkeleton v-else />
      </div>

      <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6">
        <CardBox :isChart="true" v-if="mainStore.negativeTopics && mainStore.loading != true">
          <NegativeTopicsBarChart v-if="mainStore.negativeTopics" />
        </CardBox>
        <CardBoxSkeleton v-else />


        <CardBox :isChart="true" v-if="mainStore.positiveTopics && mainStore.loading != true">
          <PositiveTopicsBarChart v-if="mainStore.positiveTopics" />
        </CardBox>
        <CardBoxSkeleton v-else />
      </div>

      <!-- <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6">
        <CardBox v-if="mainStore.wordFrequency">
          <WordCloud :words="mainStore.wordFrequency" />
        </CardBox>
        <CardBoxSkeleton v-else />


      </div> -->





      <!-- <div
        class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6"
      >
        <CardBox>
          <HorizontalBarChart
            v-if="chartConfig.topicSeries"
            :labels="chartConfig.topicLabels"
            :series="chartConfig.topicSeries"
            chartLabel="Top 5 Topics"
          />
        </CardBox>
        <CardBox>
          <StackedHorizontalBarChart v-if="chartConfig.topicSeriesBySentiment"
            :labels="chartConfig.topicLabels"
            :series="chartConfig.topicSeriesBySentiment"
            chartLabel="Sentiment By Topic"
          />
        </CardBox>
      </div> -->

      <!--
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <div class="flex flex-col justify-between">
          <CardBoxTransaction
            v-for="(transaction, index) in transactionBarItems"
            :key="index"
            :amount="transaction.amount"
            :date="transaction.date"
            :business="transaction.business"
            :type="transaction.type"
            :name="transaction.name"
            :account="transaction.account"
          />
        </div>
        <div class="flex flex-col justify-between">
          <CardBoxClient
            v-for="client in clientBarItems"
            :key="client.id"
            :name="client.name"
            :login="client.login"
            :date="client.created"
            :progress="client.progress"
          />
        </div>
      </div> -->

      <!-- <SectionBannerStarOnGitHub class="mt-6 mb-6" /> -->

      <!-- <SectionTitleLineWithButton :icon="mdiChartPie" title="Trends overview">
        <BaseButton
          :icon="mdiReload"
          color="whiteDark"
          @click="fillChartData"
        />
      </SectionTitleLineWithButton>

      <CardBox class="mb-6">
        <div v-if="chartData">
          <line-chart :data="chartData" class="h-96" />
        </div>
      </CardBox>

      <SectionTitleLineWithButton :icon="mdiAccountMultiple" title="Clients" />

      <NotificationBar color="info" :icon="mdiMonitorCellphone">
        <b>Responsive table.</b> Collapses on mobile
      </NotificationBar>

      <CardBox has-table>
        <TableSampleClients />
      </CardBox> -->
    </SectionMain>
  </LayoutAuthenticated>
</template>
