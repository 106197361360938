<template>
  <div class="chart-container">
    <Bar :data="data" :options="chartOptions"></Bar>
  </div>
</template>

<style scoped>
.chart-container {
  height: 100%;
  min-height: 350px;
}
</style>

<script setup>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
</script>

<script>
export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartLabel: {
      type: String,
      required: true,
    },
  },
  computed: {
    data() {
      return {
        labels: this.chartData.dates,
        datasets: this.chartData.series
      };
    },
    chartOptions() {
      return {
        interaction: {
          mode: 'index'
        },
        scales: {
          x: {
            title: {
              display: true,
            },
            ticks: {
              autoSkip: true,
              rotation: 90,
            },
            stacked: true,
          },
          y: {
            title: {
              display: true,
            },
            stacked: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.chartLabel,
            align: 'start',
          },
          legend: {
            display: true,
            position: 'bottom',
          },
        }
      };
    },
  },
  watch: {
    chartData(newchartData) {

      if (this.$refs.chart) {
        this.$refs.chart.updateSeries(newchartData.series);
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: newchartData.dates
          },

        });
      }
    },
  },
};
</script>
