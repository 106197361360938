<template>
  <div class="chartContainer">
    <apexchart height="100%" ref="chart" width="100%" type="line" :label="chartLabel" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style scoped>
  .chartContainer {
    height: 100%;
    min-height: 350px;
  }
</style>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    chartLabel: {
      type: String,
      required: true,
    },
  },
  data() {

    let numbers = this.series[0].data
    const maxNumber = Math.max(...numbers);
    const minNumber = Math.min(...numbers);
    let range = (maxNumber - minNumber)

    let graphCutoff = Math.round((maxNumber / range) * 100 )

    return {
      chartOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 0,
          },
        },
        xaxis: {
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: true,
            trim: true,
          },
          categories: this.labels // Set initial categories with provided labels
        },
        title: {
          text: this.chartLabel
        },
        yaxis: [{

          title: {
            text: 'Sentiment'
          },
          min: -100,
          max: 100,
        },
        {
          title: {
            text: 'Review Count',
          },
          opposite: true,

        }],
        colors: ['#B72136', '#25A0FC'],
        fill: {
          type: ["gradient", "solid"],
          //opacity:[0.8, 1, 1],
          //type: ["fill", 'fill', 'fill'],

          gradient: {
            shadeIntensity: 0,
            type: 'vertical',
            // shadeIntensity: 100,
            opacityFrom: 1,
            opacityTo: 1,
            gradientToColors: ['#007B55'],
            stops: [graphCutoff, graphCutoff]
          }
        },

        stroke: {
          curve: 'smooth',
          width: [4, 0],
        },

        // Other chart options...
      }
    }
  },
  watch: {
    labels(newLabels) {
      this.updateChart(newLabels);
    },
    series(newSeries) {
      if (this.$refs.chart) {
        this.$refs.chart.updateSeries(newSeries);
      }
    }
  },
  methods: {
    updateChart(newLabels) {
      let numbers = this.series[0].data
      const maxNumber = Math.max(...numbers);
      const minNumber = Math.min(...numbers);
      let range = (maxNumber - minNumber)

      let graphCutoff = Math.round((maxNumber / range) * 100 )

      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: newLabels
          },
          fill : {
            gradient: {
              stops: [graphCutoff, graphCutoff],
            }
          }
        });
      }
    }
  },
  mounted() {
    // Chart initialization logic here if needed
    // Access the chart instance using this.$refs.chart
  }
};
</script>
