<script setup>
import { useMainStore } from "@/stores/main";
import UserAvatar from "@/components/UserAvatar.vue";

const { userName, userAvatar } = useMainStore();

</script>

<template>
  <UserAvatar :username="userName" :avatar="userAvatar">
    <slot />
  </UserAvatar>
</template>
