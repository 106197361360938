<script setup>
import { computed } from "vue";

const props = defineProps({
  username: {
    type: String,
    default: null,
  },
  avatar: {
    type: String,
    default: null,
  },
  api: {
    type: String,
    default: "avataaars",
  },
});

const avatar = computed(
  () =>
    props.avatar ? props.avatar : props.username ?
    `https://api.dicebear.com/7.x/initials/svg?radius=0&seed=${props.username[0]}` : null
);

const username = computed(() => props.username);

</script>

<template>
  <div>
    <img
      :src="avatar"
      :alt="username"
      class="rounded-full block h-auto w-full max-w-full bg-gray-100 dark:bg-slate-800"
    />
    <slot />
  </div>
</template>
