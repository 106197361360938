<script setup>
import { reactive, watch } from 'vue';
import { useMainStore } from "@/stores/main";
import StackedHorizontalBarChart from "@/components/Charts/StackedHorizontalBarChart.vue";

const mainStore = useMainStore();

const chartData = reactive({
  platformBySentimentLabels: [],
  topicSeriesBySentiment: []
});

const updateChartData = () => {
  chartData.platformBySentimentLabels = mainStore.sentimentByPlatform.map(item => item.source.toUpperCase());

  chartData.topicSeriesBySentiment = [
    {
      name: "Positive",
      data: mainStore.sentimentByPlatform.map(item => item.PositiveCount),
      color: "#10B981"
    },
    {
      name: "Negative",
      data: mainStore.sentimentByPlatform.map(item => item.NegativeCount),
      color: "#F05252"
    },
    {
      name: "Neutral",
      data: mainStore.sentimentByPlatform.map(item => item.NeutralCount),
      color: "#3F83F8"
    }
  ];
};

watch(() => mainStore.sentimentByPlatform, () => {
  updateChartData();
});

updateChartData();
</script>

<template>
  <StackedHorizontalBarChart
    chartLabel="Sentiment By Platform"
    :labels="chartData.platformBySentimentLabels"
    :series="chartData.topicSeriesBySentiment"
  />
</template>
