export const darkModeKey = "darkMode";
export const styleKey = "style";
export const containerMaxW = "xl:max-w-full xl:mx-4";

let BASE_API_URL = "http://127.0.0.1:8090/";

if (import.meta.env.PROD) {
  BASE_API_URL = "https://api.buzztracker.co.za/";
}
export { BASE_API_URL };
