<script setup>
import { ref, reactive, watch } from 'vue';
import { useMainStore } from "@/stores/main";
import LineColumnChart from './LineColumnChart.vue';
import { mapPeriodToDateRange } from '@/utils';

const mainStore = useMainStore();

// Use reactive to make chartData reactive
const chartData = reactive(mainStore.reviewVolumeAndSentiment);

// Watch for changes in chartData and update the chart
watch(() => mainStore.reviewVolumeAndSentiment, (newChartData) => {
  Object.assign(chartData, newChartData);
});

</script>

<template>
  <LineColumnChart v-if="chartData.series.length > 0 && chartData.series.length > 0"
    chartLabel="Review Sentiment & Volume" :labels="chartData.dates" :series="chartData.series" />
</template>
